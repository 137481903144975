import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  recapitulatifLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    // Section infos
    "& > section:first-of-type": {
      display: "flex",
      flex: 2,
      flexDirection: "column",

      "& > div": {
        marginBottom: "25px",
      },
    },

    // Bloc coordonnées de signature
    "& > div:first-of-type": {
      backgroundColor: "red",
      margin: "auto",
    },

    // Boutons en bas de section
    "& > div:last-of-type": {
      " & section": {
        display: "flex",
        margin: "auto",
        whiteSpace: "nowrap",
        "& > button:first-of-type": { marginRight: 25 },
      },
    },
  },

  popinLayout: {
    padding: theme.spacing(2),
    "& > div": {
      marginTop: 25,
      marginBottom: 15,
      width: "100%",
    },
    "& > p": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    // Cache les flèches de l'input dans Firefox
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    // Cache les flèches de l'input pour les autres navigateurs
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  boutonsValidation: {},
}));

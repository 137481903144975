import axiosClient from "@/api/axiosClient";
import { Souscription } from "@/types/Souscription";
import { PageableReponse, PayloadWithPageAndFilter } from '@/types/ApiResponse';

const API_BASE_URL = "/v3/souscription";

export interface SouscriptionApiType {
  id: number;
  uuid: string;
  numDevis: string;
  dateCreation: Date;
  dateDerniereModification: Date;
  souscription: Souscription;
}

interface ParcoursUuidType {
  parcoursUuid: string;
}

const souscriptionApi = {
  async persisteInformationsSouscriptionPNO(
    parcoursJson: Partial<Souscription>,
    idParcours: string | null
  ) {
    let url;
    if (idParcours) url = `${API_BASE_URL}/pno/${idParcours}`;
    else url = `${API_BASE_URL}/pno`;

    return await axiosClient.post<ParcoursUuidType>(url, parcoursJson);
  },

  async recupereInformationsSouscriptionPNO(idParcours: string) {
    let url = `${API_BASE_URL}/pno/${idParcours}`;

    return await axiosClient.get<Souscription>(url);
  },

  async recupereSouscriptionsEnCoursPNO(params: PayloadWithPageAndFilter) {
    const url = `${API_BASE_URL}/pno/souscriptionsEnCours`;

    return await axiosClient.post<PageableReponse<SouscriptionApiType>>(url, params);
  },
  async desactiverSouscriptionEnCoursPNO(uuid: string) {
    const url = `${API_BASE_URL}/${uuid}`;

    return await axiosClient.put<PageableReponse<SouscriptionApiType>>(url);
  },

};

export default souscriptionApi;

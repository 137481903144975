import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { downloadFileFromLink } from "@/utils";
import premiumApi from "@/api/premiumApi";
import { QuittanceLight } from "@/types/Quittance";
import PdfViewVer from "@/components/PdvViewver";
import Popin from "@/components/Popin";

interface VisualisationPDFProps {
  quittance: QuittanceLight;
}
export const VisualisationPDF: React.FC<VisualisationPDFProps> = ({
  quittance,
}) => {
  const { t } = useTranslation();

  const [afficherPopin, setAfficherPopin] = useState(false);
  const [urlPDF, setUrlPDF] = useState<string | null>(null);
  const [erreurGenerationPDF, setErreurGenerationPDF] = useState(false);

  const generarerPdf = async () => {
    try {
      const blob = await premiumApi.generatePremium(quittance.premiumUuid);
      setUrlPDF(URL.createObjectURL(new Blob([blob])));
    } catch (error) {
      setUrlPDF(null);
      setErreurGenerationPDF(true);
    }
  };

  const telechargerPdf = () => {
    if (urlPDF) {
      downloadFileFromLink(
        urlPDF,
        `Quittance ${quittance.premiumNumber} - ${quittance.state.code} ${quittance.amount}€`,
        "pdf"
      );
    }
  };

  useEffect(() => {
    if (afficherPopin && !urlPDF && !erreurGenerationPDF) {
      generarerPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afficherPopin]);

  const popinTitle = `${t("dashboard.dmri.quittances.visualisation")} ${quittance.premiumNumber
    }`;

  return (
    <>
      <div onClick={() => setAfficherPopin(true)} style={{ cursor: "pointer" }}>
        <PictureAsPdfIcon />
      </div>
      <Popin
        title={popinTitle}
        content={<PdfViewVer filePath={urlPDF} error={erreurGenerationPDF} />}
        open={afficherPopin}
        maxWidth="lg"
        onClose={() => setAfficherPopin(false)}
        showCancelButton={true}
        cancelButtonLabel={t("common.popin.btn_close")}
        isValidateButtonDisabled={!urlPDF || erreurGenerationPDF}
        onValidate={telechargerPdf}
        validateButtonLabel={t("common.download")}
      />
    </>
  );
};

import { TypeURL, URLS } from "@/constants/urls";
import { TFunction } from "i18next";

interface MenuItem {
  id: string;
  link: TypeURL;
  label: string;
  icon: string;
  subItems?: MenuItem[];
  show: Boolean;
}

export const items = (t: TFunction<"translation", undefined>): MenuItem[] => [
  {
    id: "board",
    link: URLS.BOARD,
    label: t("dashboard.menu.dashboard"),
    icon: "fas fa-clipboard",
    show: true,
  },
  {
    id: "mri",
    link: URLS.MRI,
    label: t("dashboard.menu.mri"),
    icon: "fas fa-building",
    subItems: [
      {
        id: "dmriContracts",
        link: URLS.MRI_DMRI,
        label: t("dashboard.menu.contracts"),
        icon: "fas fa-folder",
        show: true,
      },
      {
        id: "quotation",
        link: URLS.MRI_QUOTE,
        label: t("dashboard.menu.quotation"),
        icon: "fas fa-file",
        show: true,
      },
    ],
    show: true,
  },
  {
    id: "pno",
    link: URLS.PNO,
    label: t("dashboard.menu.pno"),
    icon: "fas fa-folder",
    subItems: [
      {
        id: "pnoContracts",
        link: URLS.PNO_CONTRACTS,
        label: t("dashboard.menu.contracts"),
        icon: "fas fa-folder",
        show: true,
      },
      {
        id: "pnoSouscription",
        link: URLS.PNO_SOUSCRIPTION,
        label: t("dashboard.menu.newContract"),
        icon: "fas fa-plus-circle",
        show: true,
      },
      {
        id: "pnoSouscription",
        link: URLS.PNO_SOUSCRIPTIONS_EN_COURS,
        label: t("dashboard.menu.souscriptionEnCours"),
        icon: "fas fa-clipboard",
        show: true,
      },
      {
        id: "pnoClaims",
        link: URLS.PNO_CLAIMS,
        label: t("dashboard.menu.ongoingClaims"),
        icon: "fas fa-folder",
        show: false,
      },
      {
        id: "pnoPremiums",
        link: URLS.PNO_PREMIUMS,
        label: t("dashboard.menu.ongoingPremiums"),
        icon: "fas fa-file",
        show: false,
      },
    ],
    show: true,
  },
  {
    id: "homeInsurance",
    link: URLS.HOME_INSURANCE,
    label: t("dashboard.menu.homeInsurancePolicy"),
    icon: "fas fa-home",
    show: false,
  },
  {
    id: "pj",
    link: URLS.PJ,
    label: t("dashboard.menu.pjContracts"),
    icon: "fas fa-check-circle",
    show: false,
  },
  {
    id: "do",
    link: URLS.DO,
    label: t("dashboard.menu.doContracts"),
    icon: "fas fa-wrench",
    show: false,
  },
  // {
  //   id: 'contractsPerMonth',
  //   link: '/contrats',
  //   label: t('dashboard.menu.contractsPerMonth'),
  //   icon: 'fas fa-folder',
  // },
  {
    id: "termPolicies",
    link: URLS.TERM_POLICIES,
    label: t("dashboard.menu.plannedPayments"),
    icon: "fas fa-calendar-check",
    show: true,
  },
  {
    id: "claims",
    link: URLS.CLAIMS,
    label: t("dashboard.menu.claims"),
    icon: "fas fa-cloud-rain",
    show: false,
  },
];

export const getParentUrl = (url: string, items: MenuItem[]): string | null => {
  for (const item of items) {
    if (item.subItems) {
      for (const subItem of item.subItems) {
        if (subItem.link === url) {
          return item.link; // Found the parent
        }
      }

      const parentUrl = getParentUrl(url, item.subItems);
      if (parentUrl) {
        return parentUrl;
      }
    }
  }

  return null;
};

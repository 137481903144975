import React from "react";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "@/theme";

import { TelechargeDevis } from "./TelechargeDevis";

interface BlocTarifProps {
  montantAnnuelAvecTA?: number;
  montantTaxeAttentat?: number;
}
export const BlocTarif: React.FC<BlocTarifProps> = ({
  montantAnnuelAvecTA,
  montantTaxeAttentat,
}) => {
  const { t } = useTranslation();
  const montantAnnuelAvecTAEntiere = montantAnnuelAvecTA? Math.floor(montantAnnuelAvecTA): "-";
  const montantAnnuelAvecTADecimale = montantAnnuelAvecTA?(montantAnnuelAvecTA% 1).toFixed(2).split(".")[1]:" ";


  return (
    <div
      style={{
        position: "absolute",
        right: 40,
      }}
    >
      <Grid
        style={{
          backgroundColor: theme.palette.primary.main,
          padding: 15,
          borderRadius: 10,
        }}
      >
        <Typography style={{ color: "white" }} variant="h3" align="center">
          {t("pno.recapitulatif.tarif")}
        </Typography>

        <Typography
          style={{ color: "white", padding: 10 }}
          variant="h4"
          align="center"
        >
          {montantAnnuelAvecTAEntiere}
          {/* Affichage des chiffres après la virgule avec un style différent */}
          <span style={{ fontSize: "50%", verticalAlign: "super" }}>
           { montantAnnuelAvecTADecimale }
          </span>
          <em style={{ fontSize: 15 }}>{t("pno.recapitulatif.euroParAn")}</em>
        </Typography>

        <Typography style={{ color: "white" }} variant="body1" align="center">
          {t("pno.recapitulatif.taxAttentat", {
            taxe: montantTaxeAttentat || "-",
          })}
        </Typography>
      </Grid>

      <TelechargeDevis />
    </div>
  );
};
